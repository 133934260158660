// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div>
    <!-- confirmationDeleteModal -->
    <a-modal
      :title="$t('label.detach.distributor')"
      @ok="handleDistributorDetach()"
      @cancel="openDetachDistributorModal=false"
      :okText="$t('label.detach')"
      cancelText="Cancel"
      v-model:visible="openDetachDistributorModal"
      :closable="true"
      centered>
      <br/>
      <LinkOutlined :style="{fontSize: '40px', color: 'red'}" />
      <br/>
      <span>{{ $t('message.detach.distributor') }}</span>
    </a-modal>
    <div class="form-layout" v-ctrl-enter="handleMinimumSalesSubmit">
      <a-spin :spinning="minimumSalesLoading">
        <a-form
          :ref="formRef2"
          :model="form2"
          :rules="rules2"
          :loading="minimumSalesLoading"
          layout="vertical"
          @finish="handleMinimumSalesSubmit"
          >
          <!-- WP Implementation -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="minimumSales" name="minimumSales">
                  <template #label>
                      <tooltip-label :title="$t('label.minimumSales')" :tooltip="$t('label.minimumSales.tooltip')"/>
                  </template>
                  <a-input-number v-model:value="form2.minimumSales"></a-input-number>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
              <a-button type="primary" @click="handleMinimumSalesSubmit">Submit</a-button>
          </a-form-item>
        </a-form>
        <br/>
        <br/>
      </a-spin>
    </div>
    <hr/>
    <br/>
    <div class="form-layout" v-ctrl-enter="handleSubmit">
      <a-spin :spinning="partnerStatusLoading">
        <a-form
          :ref="formRef"
          :model="form"
          :rules="rules"
          :loading="partnerStatusLoading"
          layout="vertical"
          @finish="handleSubmit"
          >
          <!-- WP Implementation -->
          <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="partnerStatus" name="partnerStatus">
                  <template #label>
                      <tooltip-label :title="$t('label.enable.partner.status')" :tooltip="$t('label.enable.partner.status.tooltip')"/>
                  </template>
                  <a-switch v-model:checked="form.partnerStatus" />
              </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item v-if="form.partnerStatus === true" ref="partnerSubscription" name="partnerSubscription">
              <template #label>
                  <tooltip-label :title="$t('label.partner.status.standard')" :tooltip="$t('label.partner.status.standard.tooltip')"/>
              </template>
              <a-select v-model:value="form.partnerSubscription" :color="form.partnerColor">
                  <a-select-option v-for="item in partnerStatusList" :key="item.ID">
                      <a-tag :color="item.color">{{ item.name }}</a-tag>
                  </a-select-option>
              </a-select>
          </a-form-item>
            </a-col>
          </a-row>
          <a-form-item>
              <a-button type="primary" @click="handleSubmit">Submit</a-button>
          </a-form-item>
        </a-form>
      </a-spin>
    </div>
    <br/>
    <hr/>
    <!-- Connect Distributor Form -->
     <br/>
      <div class="form-layout" v-ctrl-enter="handleDistributorSubmit">
        <a-spin :spinning="partnerStatusLoading">
          <a-form
            :ref="formRef3"
            :model="form3"
            :rules="rules3"
            :loading="distributorLoading"
            layout="vertical"
            @finish="handleDistributorSubmit"
            >
            <a-row :gutter="12">
            <a-col :md="24" :lg="12">
              <a-form-item ref="connectDistributor" name="connectDistributor">
                <template #label>
                    <tooltip-label :title="$t('label.select.distributor')" :tooltip="$t('message.select.distributor')"/>
                </template>
                <a-select v-model:value="form3.connectDistributor" placeholder="No Distributor attached">
                    <a-select-option v-for="distributor in distributorList" :key="distributor.Uuid">
                        <span>{{ distributor.Name }}</span>
                    </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="24" :lg="12">
              <a-form-item ref="partnerNumber" name="partnerNumber">
                  <template #label>
                      <tooltip-label :title="$t('label.partnerNumber')" :tooltip="$t('label.partnerNumber.tooltip')"/>
                  </template>
                  <a-input v-model:value="form3.partnerNumber" :placeholder="$t('label.partnerNumber.tooltip')"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
            <a-form-item>
              <div class="distributorButtons">
                <a-button type="primary" @click="handleDistributorSubmit">Submit</a-button>
                <a-button type="primary" danger @click="openDetachDistributorModal=true">{{ $t('label.detach') }}</a-button>
              </div>
            </a-form-item>
          </a-form>
        </a-spin>
      </div>
  </div>
</template>
<script>
import { ref, reactive, toRaw } from 'vue'
import api2 from '@/wpApi/api2'
import wpapi from '@/wpApi/myApi'
import TooltipLabel from '@/components/widgets/TooltipLabel'
import { LinkOutlined } from '@ant-design/icons-vue'

export default {
  name: 'PartnerStatus',
  components: {
    TooltipLabel,
    LinkOutlined
  },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      account: this.$route.params.id,
      assignedDistributor: [],
      distributorList: [],
      distributorLoading: false,
      partnerStatusLoading: false,
      minimumSalesLoading: false,
      openDetachDistributorModal: false,
      partnerStatusList: []
    }
  },
  created () {
    this.initForm()
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.formRef2 = ref()
      this.formRef3 = ref()
      this.form = reactive({})
      this.form2 = reactive({
        minimumSales: 0
      })
      this.form3 = reactive({})
      this.rules = reactive({
        partnerSubscription: [{ required: true, message: this.$t('message.error.required.input') }]
      })
      this.rules2 = reactive({
        minimumSales: [{ required: true, message: this.$t('message.error.required.input') }, { validator: this.validateMinSales }]
      })
      this.rules3 = reactive({})
    },
    async getMinimumSales () {
      wpapi.getMinimumSales(this.account).then(response => {
        this.form2.minimumSales = response.data
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: `${this.$t('message.error.get.minimum.sales')}` + ' .' + error.response.data,
          duration: 0
        })
      })
    },
    async fetchDistributors () {
      await api2.listDistributors().then(response => {
        this.distributorList = response.data
      }).catch(error => {
        this.$notification.error({
          message: this.$t('label.error'),
          description: error.response.data,
          duration: 0
        })
      })
    },
    async fetchAssignedDistributor () {
      var params = new FormData()
      params.append('accountUuid', this.$route.params.id)
      await api2.getResellerDistributor(params).then(response => {
        this.assignedDistributor = response.data
        if (this.assignedDistributor === undefined || this.assignedDistributor.includes('No record found for selected reseller admin:')) {
          this.form3.connectDistributor = undefined
        } else {
          this.form3.connectDistributor = this.assignedDistributor
        }
        var distributorFilter = this.distributorList.filter(x => x.Uuid === this.assignedDistributor)[0]
        if (distributorFilter !== null && distributorFilter !== undefined && distributorFilter !== '') {
          var partnerNr = distributorFilter.ResellerAccounts.filter(x => x.ResellerUuid === this.$route.params.id)[0].PartnerNumber
        } else {
          partnerNr = null
        }
        if (partnerNr !== null && partnerNr !== undefined) {
          this.form3.partnerNumber = partnerNr
        } else {
          this.form3.partnerNumber = undefined
        }
      }).catch(error => {
        this.form3.connectDistributor = undefined
        console.log(error)
        /* this.$notification.error({
          message: this.$t('label.error'),
          description: error.response.data,
          duration: 0
        }) */
      })
    },
    async fetchData () {
      await this.getMinimumSales()
      api2.getPartnerSub(this.$route.params.id).then(response => {
        this.form.partnerStatus = response.data.status
        this.form.partnerSubscription = response.data.plan.ID
        this.form.partnerColor = response.data.plan.color
      }).catch(error => {
        console.log(error)
      })
      api2.getAllPartnerPlans().then(response => {
        this.partnerStatusList = response.data
      }).catch(error => {
        console.log(error)
      })
      await this.fetchDistributors()
      await this.fetchAssignedDistributor()
    },
    handleSubmit () {
      if (this.partnerStatusLoading) return
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)

        this.partnerStatusLoading = true
        // WP Implementation
        if (values.partnerStatus === false) {
          var disableAccount = new FormData()
          disableAccount.append('uuid', this.account)
          api2.disablePartner(disableAccount).then(response => {
            this.$notification.success({
              message: this.$t('label.change.partnerStatus'),
              description: `${this.$t('message.success.change.partnerStatus')}`
            })
          }).catch(error => {
            console.log(error)
          }).finally(() => {
            this.partnerStatusLoading = false
          })
          api2.getPartnerSub(this.$route.params.id).then(response => {
            this.form.partnerSubscription = response.data.plan.ID
          }).catch(error => {
            console.log(error)
          })
        }
        if (values.partnerStatus === true) {
          var enableAccount = new FormData()
          enableAccount.append('uuid', this.account)
          api2.enablePartner(enableAccount).then(response => {
            var wpParams = new FormData()
            wpParams.append('uuid', this.account)
            wpParams.append('planid', values.partnerSubscription)
            api2.setPartner(wpParams).then(response => {
              this.$notification.success({
                message: this.$t('label.change.partnerStatus'),
                description: `${this.$t('message.success.change.partnerStatus')}`
              })
            }).catch(error => {
              this.$notification.error({
                message: this.$t('message.request.failed'),
                description: error.response.data,
                duration: 0
              })
              console.log(error)
            }).finally(() => {
              this.partnerStatusLoading = false
            })
          }).catch(error => {
            console.log(error)
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    validateMinSales (rule, value) {
      const errorMessage = this.$t('label.minimumSales.validationText')
      if (value >= 0) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    handleMinimumSalesSubmit () {
      if (this.minimumSalesLoading) return
      this.formRef2.value.validate().then(() => {
        this.minimumSalesLoading = true
        const values = toRaw(this.form2)
        var minimumSalesAccount = new FormData()
        minimumSalesAccount.append('accountUuid', this.$route.params.id)
        minimumSalesAccount.append('minimumSales', values.minimumSales)
        wpapi.setMinimumSales(minimumSalesAccount).then(response => {
          this.minimumSalesLoading = false
          this.$notification.success({
            message: this.$t('label.success'),
            description: `${this.$t('message.set.minimum.sales')}`
          })
        }).catch(error => {
          this.minimumSalesLoading = false
          console.log(error)
          this.$notification.error({
            message: this.$t('label.error'),
            description: `${this.$t('message.error.set.minimum.sales')}` + ' .' + error.response.data,
            duration: 0
          })
        })
        // WP Implementation
      }).catch(error => {
        console.log(error)
      })
    },
    handleDistributorSubmit () {
      if (this.distributorLoading) return
      this.formRef3.value.validate().then(() => {
        this.distributorLoading = true
        const values = toRaw(this.form3)
        var setDistributorParam = new FormData()
        setDistributorParam.append('distributorUuid', values.connectDistributor)
        setDistributorParam.append('accountUuid', this.account)
        if (values.partnerNumber !== null && values.partnerNumber !== undefined) {
          setDistributorParam.append('partnerNumber', values.partnerNumber)
        } else {
          setDistributorParam.append('partnerNumber', '')
        }
        api2.assignDistributor(setDistributorParam).then(response => {
          this.distributorLoading = false
          this.$notification.success({
            message: this.$t('label.success'),
            description: `${this.$t('message.success.create.distributor.connection')}`
          })
        }).catch(error => {
          this.distributorLoading = false
          this.form3.connectDistributor = this.assignedDistributor
          console.log(error)
          this.$notification.error({
            message: this.$t('label.error'),
            description: `${this.$t('message.error.create.distributor.connection')}` + '. ' + error.response.data,
            duration: 0
          })
        })
        // WP Implementation
        this.distributorLoading = false
      }).catch(error => {
        console.log(error)
        this.distributorLoading = false
      })
    },
    handleDistributorDetach () {
      this.distributorLoading = true
      var detachDistributorParam = new FormData()
      detachDistributorParam.append('accountUuid', this.account)
      if (this.form3.partnerNumber !== null && this.form3.partnerNumber !== undefined) {
        detachDistributorParam.append('partnerNumber', this.form3.partnerNumber)
      } else {
        detachDistributorParam.append('partnerNumber', '')
      }
      api2.removeResellerDistributor(detachDistributorParam).then(response => {
        this.form3.connectDistributor = undefined
        this.form3.partnerNumber = undefined
        this.distributorLoading = false
        this.openDetachDistributorModal = false
        this.$notification.success({
          message: this.$t('label.success'),
          description: `${this.$t('message.success.create.distributor.connection')}`
        })
      }).catch(error => {
        this.distributorLoading = false
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: `${this.$t('message.error.create.distributor.connection')}` + '. ' + error.response.data,
          duration: 0
        })
        this.openDetachDistributorModal = false
      })
      // WP Implementation
      this.openDetachDistributorModal = false
      this.distributorLoading = false
    }
  }
}
</script>
<style scoped lang="less">
  .distributorButtons{
    display: flex;
    justify-content: space-between;
    width: 160px;
  }
  .form-layout {
    width: 80vw;
    @media (min-width: 600px) {
      width: 450px;
    }
  }
</style>
